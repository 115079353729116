import client from '@/api/loginAPI.js';
import { ErrorService } from '@/services/error-service.js';
import router from '@/router';
import i18n from '@/i18n';

const state = {
  terms: null,
  error: null,
  success: null
};

const getters = {
};

const actions = {
  GET_TERMS_OF_USE: async ({ commit }) => {
    const response = await client.getTermsOfUse();
    if (response.status === 200) {
      commit('SET_TERMS', response.data);
      commit('SET_SUCCESS', {
        response: response,
        message: ''
      });
    }
    if (response.status === 404) {
      commit('SET_ERROR', {
        response: response,
        message: i18n.t('messages.error')
      });
    }
  },
  AGREE_TO_TERMS_OF_USE: async ({ commit }, { has_agreed, authorizedRedirections}) => {
    const response = await client.postTermsOfUseAgreement({ has_agreed: has_agreed });
    if (response.status === 200) {
      commit('SET_SUCCESS', {
        response: response,
        message: ''
      });
      if (
        authorizedRedirections.some(reg => {
          return reg.test(decodeURIComponent(router.currentRoute.query.next))
        })
      ) {
        window.location.href = router.currentRoute.query.next;
      } else {
        router.push({ name: 'NotFound' });
      }
    }
    if (response.status === 404) {
      commit('SET_ERROR', {
        response: response,
        message: i18n.t('messages.error')
      });
    }
  }
};

const mutations = {
  SET_TERMS: (state, payload) => {
    state.terms = payload;
  },
  SET_ERROR: (state, payload) => {
    state.success = null;
    ErrorService.onError(payload.response);
    state.error = payload.message;
  },
  SET_SUCCESS: (state, payload) => {
    state.error = null;
    state.success = payload.message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
