import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import router from '@/router';

export class ErrorService {

  static onError(error) {
    const response = error;
    if (response && response.status === 403) {
      router.push({ name: '403Page'});
    }
    if (response && response.status >= 400 && response.status < 405) {
      const errorObj = response.data.detail ? response.data.detail : response.data;
      const messages = [];
      function recurse(obj) {
        for (const [key, value] of Object.entries(obj)) {
          if (typeof value === 'string') {
            messages.push(`<li>${value}</li>`);
          } else {
            recurse(value);
          }
        }
      }
      if (typeof errorObj === 'object') {
        recurse(errorObj);
      } else if (typeof errorObj === 'string') {
        messages.push(`<li>${errorObj}</li>`);
      }

      // Toastr option for error messages
      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: true,
        progressBar: false,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '0',
        extendedTimeOut: '0',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
      }

      toastr.error(`<ul>${messages.join('\n')}</ul>`, `Oups!`);
    }
    return false;
  }

  static onSuccess(response, message) {

    // Toastr option for success messages
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '5000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    }

    if (response) {
      toastr.error(message);
    }
    return false;
  }
}
