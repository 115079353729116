import client from '@/api/loginAPI.js';
import i18n from '@/i18n';

const state = {
  username: null,
  password: null,
  logged: false,
  error: null,
  next: null,
};

const getters = {
};

export const POST_SIGNIN = 'POST_SIGNIN';

const actions = {
  [POST_SIGNIN]: async ({ commit }) => {
    const data = {
      username: state.username,
      password: state.password,
    };
    await client.signIn(data)
      .then(
        () => {
          commit('SET_ERROR', null);
          commit('SET_LOGGED', true);
        },
      )
      .catch(
        (error) => {
          if (error.response.status === 403) {
            commit(
              'SET_ERROR',
              error.response.data.detail
              || i18n.t('messages.loginError'),
            );
            commit('SET_LOGGED', false);
          }
        },
      );
  },
};

export const SET_LOGGED = 'SET_LOGGED';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_ERROR = 'SET_ERROR';
export const SET_NEXT = 'SET_NEXT';

const mutations = {
  [SET_LOGGED]: (state, value) => {
    if (value === true) {
      state.logged = true;
    } else {
      state.logged = false;
    }
  },
  [SET_USERNAME]: (state, value) => {
    state.username = value;
  },
  [SET_PASSWORD]: (state, value) => {
    state.password = value;
  },
  [SET_ERROR]: (state, value) => {
    state.error = value;
  },
  [SET_NEXT]: (state, value) => {
    state.next = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
