<template>
  <div
    id="app"
    :class="{
      'custom-header-footer': $config.customHeaderAndFooter
    }"
  >
    <ogs-header
      v-if="$config.customHeaderAndFooter"
    />
    <LocaleChanger />
    <router-view id="page" />
    <ogs-footer
      v-if="$config.customHeaderAndFooter"
    />
  </div>
</template>

<script>
import LocaleChanger from '@/components/LocaleChanger.vue';

export default {
  components: {
    LocaleChanger,
  },
};
</script>
