import client from '@/api/loginAPI.js';
import i18n from '@/i18n';
import axios from 'axios';

const state = {
  logged: null,
  error: null
};

const getters = {
};


const actions = {
  GET_SIGNOUT: async ({ commit }) => {
    const EXTERNAL_LOGOUT_URL = process.env.VUE_APP_EXTERNAL_LOGOUT_URL;
    const EXTERNAL_LOGOUT_METHOD = process.env.VUE_APP_EXTERNAL_LOGOUT_METHOD;
    Promise.all([
      client.signOut(),
      EXTERNAL_LOGOUT_URL ?
        axios[EXTERNAL_LOGOUT_METHOD ? EXTERNAL_LOGOUT_METHOD : 'get'](EXTERNAL_LOGOUT_URL) :
        null
    ])
      .then(
        () => {
          commit('SET_ERROR', undefined);
          commit('SET_LOGGED', false);
        },
      )
      .catch(
        (error) => {
          commit(
            'SET_ERROR',
            error.response.data
            || i18n.t('messages.error'),
          );
          commit('SET_LOGGED', true);
        },
      );
  },
};


const mutations = {
  SET_LOGGED: (state, value) => {
    if (value === true) {
      state.logged = true;
    } else {
      state.logged = false;
    }
  },
  SET_ERROR: (state, payload) => {
    state.error = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
