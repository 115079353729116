import client from '@/api/loginAPI.js';
import { ErrorService } from '@/services/error-service.js';
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import router from '@/router';
import i18n from '@/i18n';

const state = {
  error: null,
  success: null
};

const getters = {
};

export const REQUEST_FORGOTTEN_PASSWORD = 'REQUEST_FORGOTTEN_PASSWORD';
export const CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD';

const actions = {
  [REQUEST_FORGOTTEN_PASSWORD]: async ({ commit }, data) => {
    const response = await client.forgottenPasswordRequest(data);
    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        heightAuto: false,
        icon: 'success',
        text: i18n.t('messages.reinitPwdRequest.success'),
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#187CC6'
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({ name: 'SignIn' });
        }
      });
    }
    if (response.status === 404) {
      commit('SET_ERROR', {
        response: response,
        message: i18n.t('messages.reinitPwdRequest.error')
      });
    }
  },

  [CONFIRM_NEW_PASSWORD]: async ({ commit }, data) => {
    const response = await client.forgottenPasswordConfirm(data);
    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        heightAuto: false,
        icon: 'success',
        text: i18n.t('messages.reinitPwdConfirm.success'),
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#187CC6'
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({ name: 'SignIn' });
        }
      });
    } else if (response.status === 400) {
      Swal.fire({
        position: 'center',
        heightAuto: false,
        icon: 'error',
        text: i18n.t('messages.reinitPwdConfirm.error'),
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#187CC6'
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({ name: 'SignIn' });
        }
      });
    }
  }
};

export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';

const mutations = {
  [SET_ERROR]: (state, payload) => {
    state.success = null;
    ErrorService.onError(payload.response);
    state.error = payload.message;
  },
  [SET_SUCCESS]: (state, payload) => {
    state.error = null;
    state.success = payload.message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
