import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/i18n';

import config from '../../public/config.js';

Vue.use(VueRouter);

function normalizePathname(pathname) {
    const normalizedPath = decodeURI(pathname)
      // Replaces repeated slashes in the URL.
      .replace(/\/+/g, "/")
      // Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
      // Note: Missing native IE support, may want to skip this step.
      .normalize()
  return `${normalizedPath}/`;
};

const routes = [
  {
    path: '/:locale',
    component: () => import('@/views/Base.vue'),
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');

      if (!locale) {
        return next({
          path: `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ? to.path : normalizePathname(to.path)}`,
          query: to.query
        });
      }

      if (locale && locale.length !== 2) {
        return next({
          path: `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ? to.path : normalizePathname(to.path)}`,
          query: to.query
        });
      }

      if (locale && !supported_locales.includes(locale)) {
        return next({
          path:
            `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.slice(3).endsWith('/') ?
              to.path.slice(3) :
              normalizePathname(to.path.slice(3))}`,
          query: to.query
        });
      }

      if (!to.path.endsWith('/')) {
        return next({
          path: normalizePathname(to.path),
          query: to.query
        });
      }

      i18n.locale = locale;

      return next();

    },
    children: [
      {
        path: '',
        // redirect: { name: 'SignIn' },  ==> IT DOES NOT WORK!
        beforeEnter: (to, from, next) => {
          return next(`${to.path}signin/`);
        }
      },
      {
        path: 'signin',
        name: 'SignIn',
        component: () => import('@/views/SignIn.vue'),
      },
      {
        path: 'signup',
        name: 'SignUp',
        component: () => import('@/views/SignUp.vue'),
        beforeEnter: (to, from, next) => {
          if (config.forms.signup.disabled) {
            return next({ path: 'not-found/' });
          } else {
            return next();
          }
        }
      },
      {
        path: 'terms-of-use',
        name: 'TermsOfUse',
        component: () => import('@/views/TermsOfUse.vue'),
      },
      {
        path: 'signout',
        name: 'SignOut',
        component: () => import('@/views/SignOut.vue'),
      },
      {
        path: 'signout-failed',
        name: 'SignOutFailed',
        component: () => import('@/views/SignOutFailed.vue'),
      },
      {
        path: 'signupsuccess',
        name: 'SignUpSuccess',
        component: () => import('@/views/SignUpSuccess.vue'),
      },
      {
        path: 'validateregistration',
        name: 'ValidationRegistration',
        component: () => import('@/views/ValidationRegistration.vue'),
      },
      {
        path: 'validate-email',
        name: 'ValidationEmail',
        component: () => import('@/views/ValidationEmail.vue'),
      },
      {
        path: 'forgottenpwd',
        name: 'ForgottenPassword',
        component: () => import('@/views/ForgottenPassword.vue'),
      },
      {
        path: 'reinitpwd',
        name: 'ReinitPassword',
        component: () => import('@/views/ReinitPassword.vue'),
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('@/views/UserProfile.vue'),
      },
      {
        path: 'not-found',
        name: 'NotFound',
        component: () => import('@/views/NotFound.vue'),
      },
    ]
  },
  {
    path: '*',
    name: 'Fallback',
    beforeEnter: (to, from, next) => {
      const matchedRoutes = router.resolve(
        `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ?
          to.path :
          normalizePathname(to.path)}`
      ).resolved.matched;
      if (
        matchedRoutes && !matchedRoutes.find(el => el.name === 'Fallback')
      ) {
        return next({
          path: `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ? to.path : normalizePathname(to.path)}`,
          query: to.query
        });
      }
      return next({
        path: 'not-found/',
      });
    }
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH || '',
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title =
      i18n.messages[i18n.locale].titles[to.name.toLowerCase()] && config.client.name ?
        `${i18n.messages[i18n.locale].titles[to.name.toLowerCase()]} - ${config.client.name}` :
        process.env.VUE_APP_TITLE;
  });
});

export default router;
