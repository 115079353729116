import Vue from 'vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import Multiselect from 'vue-multiselect';

// Custom css
import '@/app.scss';
import '@/app.less';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('Multiselect', Multiselect);

import App from '@/App.vue';
import i18n from './i18n';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'ogs-header',
  'ogs-footer'
];

import config from '../public/config.js';

Vue.mixin({
  methods: {
    isFieldVisible(field, hiddenFields) {
      return !hiddenFields.includes(field);
    },
    isFieldRequired(field, requiredFields) {
      return requiredFields.includes(field) ? 'required' : '';
    }
  }
});

Vue.prototype.$config = config;

// Use WC header and footer if configured
if (config.customHeaderAndFooter) {
  const headElement = document.getElementsByTagName('head')[0];
  let s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = '/portal/wc/ogs-header.js';
  headElement.append(s);
  s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = '/portal/wc/ogs-footer.js';
  headElement.append(s);
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
