import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/assets/locales/en.json';
import fr from '@/assets/locales/fr.json';

// const defaultImpl = VueI18n.prototype.getChoiceIndex;

// VueI18n.prototype.getChoiceIndex = function(choice, choicesLength) {
//   // this === VueI18n instance, so the locale property also exists here
//   if (this.locale !== 'fr') {
//     // proceed to the default implementation
//     return defaultImpl.apply(this, arguments)
//   }

//   if (choice === 0) {
//     return 0;
//   }

//   const teen = choice > 10 && choice < 20;
//   const endsWithOne = choice % 10 === 1;

//   if (!teen && endsWithOne) {
//     return 1;
//   }

//   if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
//     return 2;
//   }

//   return (choicesLength < 4) ? 2 : 3;
// }

Vue.use(VueI18n);

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  'fr': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  }
};

const numberFormats = {
  'fr': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  }
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: { en, fr },
  dateTimeFormats,
  numberFormats
});

export default i18n;
