<template>
  <div id="locale-changer">
    <select data-test="locale" v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
import router from '@/router';

export default {

  name: 'LocaleChanger',

  data () {
    return {
      langs: ['fr', 'en'],
    }
  },

  watch: {
    '$i18n.locale': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        document.documentElement.lang = `${newValue}-${newValue.toUpperCase()}`;
        const routeName = router.currentRoute.name.toLowerCase();
        document.title =
          `${this.$i18n.messages[newValue].titles[routeName]} - ${this.$config.client.name}` ||
          process.env.VUE_APP_TITLE;
        const to = this.$router.resolve({
          params: { locale: newValue },
          query: this.$route.query
        });
        this.$router.push(to.location);
      }
    }
  }

}
</script>
