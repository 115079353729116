import client from '@/api/loginAPI.js';
import organisationAPI from '@/api/organisationsAPI.js';
import usergroupsAPI from '@/api/usergroupsAPI.js';
import i18n from '@/i18n';

import { ErrorService } from '@/services/error-service.js';

const state = {
  form: {
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    comments: null,
    username: null,
    password1: null,
    password2: null
  },
  organisationThumbnail: null,
  organisationSpheres: [],
  signed: false,
  error: null,
};

const getters = {
  getForm: state => state.form,
  getSigned: state => state.signed,
  getError: state => state.error,
};

const actions = {
  POST_SIGNUP: async ({ state, commit }) => {
    try {
      const resp = await client.signUp(state.form);
        if (resp) {
          if (state.organisationThumbnail) {
            await organisationAPI.setOrganisationThumbnail(resp.usergroup_roles[0].organisation.id, state.organisationThumbnail)
          }
          if (state.organisationSpheres && state.organisationSpheres.length > 0) {
            const data = {
              ...resp.usergroup_roles[0].usergroup,
              usergroup_type: {
                id: resp.usergroup_roles[0].usergroup.usergroup_type,
                codename: 'organisation',
                description: 'Organisation',
                display_name: 'Organisation',
                display_name_plural: 'Organisations',
              },
              parents: state.organisationSpheres.map((el) => { return el.id; })
            };
            await usergroupsAPI.updateUsergroup(resp.usergroup_roles[0].usergroup.id, data)
          }
          commit('SET_ERROR', undefined);
          commit('SET_SIGNED', true);
        }
    } catch (error) {
      commit(
        'SET_ERROR',
        error.response
        || i18n.t('messages.error'),
      );
      commit('SET_SIGNED', false);
    }
  },
};

const mutations = {
  SET_FORM: (state, data) => {
    state.form = data.form;
    state.organisationThumbnail = data.thumbnail;
    state.organisationSpheres = data.spheres;
  },
  SET_SIGNED: (state, value) => {
    if (value === true) {
      state.signed = true;
    } else {
      state.signed = false;
    }
  },
  SET_ERROR: (state, value) => {
    ErrorService.onError(value);
    state.error = value && value.data ? value.data : value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
