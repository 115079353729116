import loginAPI from '@/api/loginAPI.js';

import { ErrorService } from '@/services/error-service.js';
import i18n from '@/i18n';

/**************** STATE *******************/
const state = {
  userData: null,
  userError: null,
  error: null,
  success: null
};

/**************** GETTERS *****************/
const getters = {

};

/*************** MUTATIONS ****************/
const mutations = {
  SET_USER_DETAIL: (state, payload) => {
    state.userData = payload;
  },

  SET_ERROR: (state, error) => {
    if (error) {
      ErrorService.onError(error);
      state.userError = error.data;
    } else {
      state.userError = error;
    }
  },

  SET_SUCCESS: (state, payload) => {
    state.error = null;
    state.success = payload.message;
  },
};
/**************** ACTIONS *****************/
const actions = {


  GET_USER_DETAIL: async ({ commit }) => {
    try {
      const resp = await loginAPI.getUserDetail();
        if (resp) {
          commit('SET_ERROR', null);
          commit('SET_USER_DETAIL', resp);
        }
      } catch (error) {
        commit('SET_ERROR', error.response);
      }
  },

  UPDATE_USER_DETAIL: async ({ commit }, data) => {
    try {
      const resp = await loginAPI.updateUserDetail(data);
      if (resp) {
        commit('SET_ERROR', null);
        commit('SET_SUCCESS', {
          response: resp,
          message: i18n.t('messages.password.success')
        });
      }
    } catch(error) {
      commit('SET_SUCCESS', {
        response: null,
        message: null
      });
      commit('SET_ERROR', error.response);
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};