import client from '@/api/loginAPI.js';

const state = {
  status: undefined,
};

export const POST_TOKEN = 'POST_TOKEN';

const actions = {
  [POST_TOKEN]: async ({ commit }, token) => {
    const data = {
      token: token
    };
    await client.newEmailConfirm(data).then(response => {
      commit('SET_STATUS', response);
    });
  },
};

export const SET_STATUS = 'SET_STATUS';

const mutations = {
  [SET_STATUS]: (state, response) => {
    if (response === true) {
      state.status = true;
    } else {
      state.status = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
