import client from '@/api/loginAPI.js';
import organisationAPI from '@/api/organisationsAPI.js';
import axios from 'axios';

const state = {
  organisationsList: [],
  organisationsTypes: [],
  organisationsRoles: [],
};

const getters = { };

export const GET_ORGANISATIONS_TYPES = 'GET_ORGANISATIONS_TYPES';
export const GET_ORGANISATIONS_ROLES = 'GET_ORGANISATIONS_ROLES';

const actions = {
  GET_ORGANISATIONS_LIST: async ({ commit }) => {
    const organisations = await client.getOrganisationsList();
    commit('SET_ORGANISATIONS_LIST', organisations);
  },
  [GET_ORGANISATIONS_TYPES]: async ({ commit }) => {
    const types = await organisationAPI.getOrganisationsTypes();
    commit('SET_ORGANISATIONS_TYPES', types);
  },
  [GET_ORGANISATIONS_ROLES]: async ({ commit }) => {
    const roles = await organisationAPI.getOrganisationsRoles();
    commit('SET_ORGANISATIONS_ROLES', roles);
  }
};

export const SET_ORGANISATIONS_TYPES = 'SET_ORGANISATIONS_TYPES';
export const SET_ORGANISATIONS_ROLES = 'SET_ORGANISATIONS_ROLES';

const mutations = {
  SET_ORGANISATIONS_LIST: (state, payload) => {
    if (payload && payload.length) {
      state.organisationsList = payload.sort((a, b) => a.display_name.localeCompare(b.display_name));
    } else {
      state.organisationsList = [];
    }
  },
  [SET_ORGANISATIONS_TYPES]: (state, payload) => {
    state.organisationsTypes = payload;
  },
  [SET_ORGANISATIONS_ROLES]: (state, payload) => {
    state.organisationsRoles = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
