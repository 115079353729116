import client from '@/api/loginAPI.js';

const state = {
  token: null,
  status: undefined,
};

const getters = {
  getStatus: state => state.status,
};

export const POST_TOKEN = 'POST_TOKEN';

const actions = {
  [POST_TOKEN]: async ({ state, commit }) => {
    const data = { token: state.token };
    await client.validationRegistration(data).then(response => {
      commit('SET_STATUS', response);
    });
  },
};

export const SET_STATUS = 'SET_STATUS';
export const SET_TOKEN = 'SET_TOKEN';

const mutations = {
  [SET_TOKEN]: (state, value) => {
    state.token = value;
  },
  [SET_STATUS]: (state, response) => {
    if (response === true) {
      state.status = true;
    } else {
      state.status = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
