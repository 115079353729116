import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_LOGIN_API_USERNAME,
  password: process.env.VUE_APP_LOGIN_API_PASSWORD
};


const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;
const LOGIN_API_PATH = process.env.VUE_APP_LOGIN_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const usergroupsAPI = {

  async getFilteredUsergroupsList(type = 'group-of-organisation', page = 1) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `user-groups/?page=${page}&usergroup_types=${type}`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateUsergroup(id, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

}

export default usergroupsAPI;
